import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
   {
    pid: 0,
    lpSymbol: 'SOS',
    lpAddresses: {
      97: '',
      56: '0xf14c1E59d5CF27CbB6E8Be3Ef9889EED093FCC1d',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 11,
    lpSymbol: 'SOS-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xaa42b015957416e94d0B4b201Ee7C271058E96F0',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 13,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x1Bed023745BC59bf4E09Dd59bDe5c376A3da35D7',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
  /**
   * V3 by order of release (some may be out of PID order due to multiplier boost)
   */
  {
    pid: 12,
    lpSymbol: 'SOS-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x6e0a31117640c75433360d456cEaEFdd1021d479',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 17,
    lpSymbol: 'SOS-EOS LP',
    lpAddresses: {
      97: '',
      56: '0xDB7B9b194e554B2F5493b4cB617397a7d8D3261D',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.eos,
  },
  {
    pid: 14,
    lpSymbol: 'SOS-BTCB LP',
    lpAddresses: {
      97: '',
      56: '0xED8a077389970cd2107CA6D5df588F2C32A96744',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.btcb,
  },
  {
    pid: 15,
    lpSymbol: 'SOS-CAKE LP',
    lpAddresses: {
      97: '',
      56: '0xA71631629F0E1B5F35A31a5b9F961F8683D9989c',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.pan,
  },
  {
    pid: 16,
    lpSymbol: 'SOS-DOGE LP',
    lpAddresses: {
      97: '',
      56: '0x92234edbe0Ef2b39fC503c645Eda798a61fef6Dd',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.doge,
  },
  {
    pid: 18,
    lpSymbol: 'SOS-ETH LP',
    lpAddresses: {
      97: '',
      56: '0x5ac520A03197168dfF3E169305c90F77793C88F3',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 19,
    lpSymbol: 'SOS-MDX LP',
    lpAddresses: {
      97: '',
      56: '0xa1797606489538E5c36DbdCD321A702fe86a420b',
    },
    token: serializedTokens.mdx,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 20,
    lpSymbol: 'SOS-SUSHI LP',
    lpAddresses: {
      97: '',
      56: '0xbe0857C1770b617f101A67E7267cBbACEF0eBe8C',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.sushi,
  },
  {
    pid: 21,
    lpSymbol: 'SOS-TRX LP',
    lpAddresses: {
      97: '',
      56: '0xddAb7B86F83cE01E4F07FdF595aeE2A0D2e12Ea7',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.trx,
  },
  {
    pid: 22,
    lpSymbol: 'SOS-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xEB9b66087864060ccd02801f09501924181c9566',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 23,
    lpSymbol: 'SOS-XRP LP',
    lpAddresses: {
      97: '',
      56: '0x514397E1Bb98880A6B4dabaaaB9D52C4679a8541',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.xrp,
  },
  {
    pid: 1,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    token: serializedTokens.btcb,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 3,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    token: serializedTokens.pan,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 4,
    lpSymbol: 'DOGE',
    lpAddresses: {
      97: '',
      56: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    },
    token: serializedTokens.doge,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 5,
    lpSymbol: 'EOS',
    lpAddresses: {
      97: '',
      56: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    },
    token: serializedTokens.eos,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 6,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 7,
    lpSymbol: 'MDX',
    lpAddresses: {
      97: '',
      56: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    },
    token: serializedTokens.mdx,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 8,
    lpSymbol: 'SUSHI',
    lpAddresses: {
      97: '',
      56: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    },
    token: serializedTokens.sushi,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 9,
    lpSymbol: 'TRX',
    lpAddresses: {
      97: '',
      56: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
    },
    token: serializedTokens.trx,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 10,
    lpSymbol: 'XRP',
    lpAddresses: {
      97: '',
      56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    },
    token: serializedTokens.xrp,
    quoteToken: serializedTokens.cake,
  },
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
