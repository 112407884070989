import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('MasterChef'),
        href: 'https://bscscan.com/address/0x8Fe4B06c0B170341e7e8c0F5A5fc1A77CCb3eD96',
        isHighlighted: true,
      },
      {
        label: t('SOS token'),
        href: 'https://bscscan.com/address/0xf14c1E59d5CF27CbB6E8Be3Ef9889EED093FCC1d',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Guides'),
        href: 'https://docs.dongas.finance/get-started',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/jjjajh/dongasfinance',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.dongas.finance',
      },
    ],
  },
]
